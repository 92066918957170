import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { execCode } from '../../store/operations/editor';
import { getConsoleLoaded, getConsoleText, getErrorText, getLoaded } from '../../store/selectors/editor';
import { actions } from '../../store/slices/editor';
import { getSessionSearchParam } from '../../utils/search-params/session-params';
import { SyncInstance } from '../../sync';
import { Console, Editor, Header, Loader, DialogLayout, TaskDescription } from '../../components';
import { TLanguageState, TMasterSettings } from '../../components/editor/index.types';

import { DEFAULT_LANGUAGE } from './constants';
import { TCollaborator, THorizontal, TLanguage, TLsp } from './index.types';

import './style.css';
import { useAppSelector } from '../../store';
import { TasksPendingSelector } from '../../store/tasks/tasks.selector';
import { CurrentWindowSelector } from '../../store/window/window.selector';
import { Windows } from '../../constants/windows.enum';
import { Box } from '@mui/material';
import { setLang, setConvergenceId } from '../../store/window/window.slice';
import { getInterviewPathName } from '../../utils/search-params/interview-params';
import { CandidateCodeSelector } from '../../store/dialog/dialog.selector';

export const EditorPage = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [lsp, setLspState] = useState<TLsp>(false);
    const fetchTasksInfoPending = useAppSelector(TasksPendingSelector);
    const currentWindow = useAppSelector(CurrentWindowSelector);
    const candidateCode = useAppSelector(CandidateCodeSelector);

    const setLsp = useCallback((v: boolean) => {
        setLspState(v);
        SyncInstance.send('lsp', v);
    }, []);

    const [languageState, setLanguageState] = useState<TLanguageState>({
        value: DEFAULT_LANGUAGE,
        isExternalChange: false,
    });

    const [horizontal, setHorizontal] = useState<THorizontal>(false);
    const [username, setUsername] = useState<string>('');
    const [isMaster, setIsMaster] = useState(false);
    const convergenceIdsMap = useRef<Record<TLanguage, string>>({});
    const convergenceId = convergenceIdsMap?.current[languageState.value];
    const [collaborators, setCollaborators] = useState<TCollaborator[]>([]);
    const [roomId, setRoomId] = useState(getSessionSearchParam('room'));
    const [interviewPath, setInterviewPath] = useState(getInterviewPathName(candidateCode || ''));
    const [isConnectClosed, setIsConnectClosed] = useState(false);

    useEffect(() => {
        dispatch(setConvergenceId(convergenceId));
        dispatch(setLang(languageState.value));
    }, [convergenceId, languageState]);

    const setLanguage = useCallback((value: TLanguage) => {
        if (!convergenceIdsMap.current[value]) {
            convergenceIdsMap.current[value] = uuid();
            SyncInstance.send('convergenceIdsMap', convergenceIdsMap.current);
        }
        setLanguageState({
            value,
            isExternalChange: false,
        });
        setLspState(false);
        SyncInstance.send('language', value);
    }, []);

    const loaded = useSelector(getLoaded);
    const errorText = useSelector(getErrorText);
    const consoleText = useSelector(getConsoleText);
    const consoleLoaded = useSelector(getConsoleLoaded);

    const onDialogSubmit = (newUsername?: string) => {
        if (newUsername) setUsername(newUsername);
        SyncInstance.send('masterSettingsReq', { data: '' });
    };

    const toggleHorizontal = () => {
        setHorizontal((prev) => !prev);
    };

    const onExecCodeButtonClick = useCallback(() => {
        if (convergenceId) dispatch(execCode({ id: convergenceId, language: languageState.value }));
    }, [dispatch, convergenceId, languageState.value]);

    useEffect(() => {
        if (errorText) {
            enqueueSnackbar(errorText, { variant: 'error' });
        }
    }, [enqueueSnackbar, errorText]);

    useEffect(() => {
        return () => SyncInstance.close();
    }, []);

    useEffect(() => {
        if (interviewPath) {
            if (!convergenceIdsMap.current[DEFAULT_LANGUAGE]) {
                convergenceIdsMap.current[DEFAULT_LANGUAGE] = localStorage.getItem('convergenceId') || uuid();
            };
        };
    }, [dispatch, interviewPath]);

    useEffect(() => {
        if (roomId) {
            SyncInstance.open(roomId);

            SyncInstance.subscribe('masterSettingsRes', (masterSettings: TMasterSettings) => {
                setLspState(masterSettings.lsp);
                convergenceIdsMap.current = masterSettings.convergenceIdsMap;
                setLanguageState({ isExternalChange: true, value: masterSettings.language });
                dispatch(actions.setConsoleLoaded(masterSettings.consoleLoaded));
                dispatch(actions.setConsoleText(masterSettings.consoleText));
            });

            SyncInstance.subscribe('lsp', (flag: boolean) => {
                setLspState(!!flag);
            });

            SyncInstance.subscribe('language', (lang: TLanguage) => {
                setLspState(false);
                setLanguageState({ isExternalChange: true, value: lang.toString() });
            });

            SyncInstance.subscribe('console', (text: string) => {
                dispatch(actions.setConsoleText(text.toString()));
            });

            SyncInstance.subscribe('consoleLoaded', (status: 'load' | 'loaded') => {
                dispatch(actions.setConsoleLoaded(status.toString() === 'load' ? 'load' : 'loaded'));
            });

            SyncInstance.subscribe('convergenceIdsMap', (map: Record<TLanguage, string>) => {
                convergenceIdsMap.current = map;
            });

            SyncInstance.subscribe('master', () => {
                if (!convergenceIdsMap.current[DEFAULT_LANGUAGE]) {
                    convergenceIdsMap.current[DEFAULT_LANGUAGE] = uuid();
                }
                setIsMaster(true);
                SyncInstance.send('master', { success: true });
            });
        }
    }, [dispatch, roomId]);

    useEffect(() => {
        if (isMaster) {
            SyncInstance.subscribe('masterSettingsReq', () => {
                const settings: TMasterSettings = {
                    lsp,
                    language: languageState.value,
                    consoleText,
                    consoleLoaded,
                    convergenceIdsMap: convergenceIdsMap.current,
                };
                SyncInstance.send('masterSettingsRes', settings);
            });
        }

        return () => {
            if (isMaster) {
                SyncInstance.unsubscribe('masterSettingsReq');
            }
        };
    }, [isMaster, lsp, languageState.value, consoleText, consoleLoaded]);

    useEffect(() => {
        if (isConnectClosed) {
            setIsConnectClosed(false);

            return () => SyncInstance.close();
        };
        return;
    }, [isConnectClosed, setIsConnectClosed]);

    return (
        <section className={cn('page page--editor editor-page', { 'editor-page--horizontal': horizontal })}>
            <Header roomId={roomId} collaborators={collaborators} />

            <Box className={cn('editor-page__main-content', currentWindow === Windows.INTERVIEW_WINDOW ? 'editor-page__task-section' : null)}>
                {currentWindow === Windows.INTERVIEW_WINDOW ? (
                    <TaskDescription horizontal={horizontal} />
                ) : null}
                <Editor
                    language={languageState.value}
                    setLanguage={setLanguage}
                    languageState={languageState}
                    convergenceId={convergenceId}
                    username={username}
                    lsp={lsp}
                    setLsp={setLsp}
                    setCollaborators={setCollaborators}
                />
            </Box>

            <Console
                text={consoleText}
                loaded={consoleLoaded}
                horizontal={horizontal}
                toggleHorizontal={toggleHorizontal}
                onExecCodeButtonClick={onExecCodeButtonClick}
                isExecCodeButtonDisabled={consoleLoaded === 'load'}
            />

            <DialogLayout
                roomId={roomId}
                onSubmit={onDialogSubmit}
                setRoomId={setRoomId}
                interviewPath={interviewPath}
                setInterviewPath={setInterviewPath}
            />

            <Loader open={loaded === 'load' || fetchTasksInfoPending} />
        </section>
    );
};
