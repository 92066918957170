import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../instance";
import { TaskThunkPrefixes } from "./constants/task-thunk-prefixes.enum";
import { TaskEndpoints } from "./constants/task-endpoints.enum";
import { TasksInfo, TasksPreviewInfo } from "../../shared/types/tasks-info.type";
import { CodeExecTestsRequest, CodeExecTestsResponse, CodeRequest, CodePartialFinalizeResponse, CodePartialFinalizeRequest, CodeFinalizeResponse, StartTestsResponse } from "../../shared/types/tests.type";
import { TDefaultAsyncThunkConfig } from "../operations/types";
import { createError } from "../operations/utils";

export const fetchTasks = createAsyncThunk<TasksInfo, string>(
  TaskThunkPrefixes.FETCH_TASKS as unknown as string,
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await instance.get(`${TaskEndpoints.FETCH_TASKS}/${code}`);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const previewTasks = createAsyncThunk<TasksPreviewInfo, CodeRequest, TDefaultAsyncThunkConfig>(
  TaskThunkPrefixes.PREVIEW as unknown as string,
  async (data: CodeRequest, { rejectWithValue }) => {
    try {
      const response = await instance.get(`${TaskEndpoints.FETCH_TASKS}/${data.userCode}${TaskEndpoints.PREVIEW}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(createError(new Error('Ошибка выполнения')))
    }
  },
);

export const startTasks = createAsyncThunk<StartTestsResponse, CodeRequest, TDefaultAsyncThunkConfig>(
  TaskThunkPrefixes.START as unknown as string,
  async (data: CodeRequest, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${TaskEndpoints.FETCH_TASKS}${TaskEndpoints.START}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(createError(new Error('Ошибка выполнения')))
    }
  },
);

export const tests = createAsyncThunk<CodeExecTestsResponse, CodeExecTestsRequest, TDefaultAsyncThunkConfig>(
  TaskThunkPrefixes.TESTS_WITH_OWN_ARGUMENTS as unknown as string,
  async (data: CodeExecTestsRequest, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${TaskEndpoints.TESTS_WITH_OWN_ARGUMENTS}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(createError(new Error('Ошибка выполнения')))
    }
  },
);

export const partialFinalize = createAsyncThunk<CodePartialFinalizeResponse, CodePartialFinalizeRequest, TDefaultAsyncThunkConfig>(
  TaskThunkPrefixes.PARTIAL_FINALIZE as unknown as string,
  async (data: CodePartialFinalizeRequest, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${TaskEndpoints.PARTIAL_FINALIZE}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(createError(new Error('Ошибка сохранения данных')))
    }
  },
);

export const finalize = createAsyncThunk<CodeFinalizeResponse, CodeRequest, TDefaultAsyncThunkConfig>(
  TaskThunkPrefixes.FINALIZE as unknown as string,
  async (data: CodeRequest, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${TaskEndpoints.FINALIZE}`, {
        userCode: data.userCode
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(createError(new Error('Ошибка сохранения данных')))
    }
  },
);

export const consolePartialFinalize = createAsyncThunk<CodePartialFinalizeResponse, CodePartialFinalizeRequest, TDefaultAsyncThunkConfig>(
  TaskThunkPrefixes.CONSOLE_PARTIAL_FINALIZE as unknown as string,
  async (data: CodePartialFinalizeRequest, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${TaskEndpoints.PARTIAL_FINALIZE}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(createError(new Error('Ошибка выполнения')))
    }
  },
);

export const consoleFinalize = createAsyncThunk<CodeFinalizeResponse, CodeRequest, TDefaultAsyncThunkConfig>(
  TaskThunkPrefixes.CONSOLE_FINALIZE as unknown as string,
  async (data: CodeRequest, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${TaskEndpoints.FINALIZE}`, {
        userCode: data.userCode
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(createError(new Error('Ошибка выполнения')))
    }
  },
);