import { createSlice } from '@reduxjs/toolkit';
import { Windows } from '../../constants/windows.enum';

type WindowState = {
    window: Windows;
    convergenceId: string | null;
    lang: string | null
};

const initialState: WindowState = {
    window: Windows.CODE_WINDOW,
    convergenceId: null,
    lang: null
};

export const WindowSlice = createSlice({
    name: 'window',
    initialState,
    reducers: {
        switchWindow: (state, action) => {
            state.window = action.payload;
        },
        setConvergenceId: (state, action) => {
            state.convergenceId = action.payload;
        },
        setLang: (state, action) => {
            state.lang = action.payload;
        },
    },
});

export const { switchWindow, setConvergenceId, setLang } = WindowSlice.actions;

export default WindowSlice.reducer;
