import { createInstance } from 'i18next';
import Backend from 'i18next-http-backend';

const instance = createInstance();

const language = localStorage.getItem('lng') || undefined;

if (!language) {
    localStorage.setItem('lng', 'ru');
}

instance.use(Backend).init({
    lng: language,
    fallbackLng: 'ru',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    defaultNS: 'default',
    backend: {
        loadPath: `locales/{{lng}}/{{ns}}.json`,
    },
    ns: ['default'],
});

instance.on('languageChanged', (lng: string) => {
    localStorage.setItem('lng', lng);
});

export { instance };
