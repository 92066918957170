import { TTemplates } from './index.types';

const PYTHON_TEMPLATE = `print("Hello World!")`;

const JAVASCRIPT_TEMPLATE = `console.log("Hello World!")`;

const JAVA_TEMPLATE = `class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello World!");
    }
}`;

const GO_TEMPLATE = `package main

import "fmt"

func main() {
    fmt.Println("Hello World!")
}`;

const KOTLIN_TEMPLATE = `package org.kotlinlang.play

fun main() {
    println("Hello, World!")
}`;

const DART_TEMPLATE = `void main() {
    print('Hello, World!');
}`;

export const TEMPLATES: TTemplates = {
    python: {
        value: PYTHON_TEMPLATE,
        extension: 'py',
    },
    javascript: {
        value: JAVASCRIPT_TEMPLATE,
        extension: 'js',
    },
    java: {
        value: JAVA_TEMPLATE,
        extension: 'java',
    },
    go: {
        value: GO_TEMPLATE,
        extension: 'go',
    },
    kotlin: {
        value: KOTLIN_TEMPLATE,
        extension: 'kt',
    },
    dart: {
        value: DART_TEMPLATE,
        extension: 'dart',
    },
};
