import React, { SyntheticEvent } from 'react';
import { Box, Button, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import {
    KeyboardArrowUpOutlined,
    KeyboardArrowDownOutlined,
    KeyboardArrowRightOutlined,
    KeyboardArrowLeftOutlined,
    PlayArrowOutlined,
    CheckBoxOutlined
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

import { TconsoleMenuProps } from './index.types';

import './style.css';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { CurrentTaskNumberSelector, TasksSelector } from '../../../../store/tasks/tasks.selector';
import { consoleFinalize, consolePartialFinalize, tests } from '../../../../store/tasks/tasks.actions';
import { RequestStatuses } from '../../../../constants/request-statuses.enum';
import { CodeExecTestsRequest, CodePartialFinalizeRequest, CodeFinalizeResponse } from '../../../../shared/types/tests.type';
import { isOpened, switchDialog } from '../../../../store/dialog/dialog.slice';
import { Dialogs } from '../../../../constants/dialogs.enum';
import { setTaskNumber } from '../../../../store/tasks/tasks.slice';
import { CandidateCodeSelector } from '../../../../store/dialog/dialog.selector';
import { CurrentConvergenceIdSelector, CurrentLangSelector } from '../../../../store/window/window.selector';
import ResultsTabIcon from '../../../../assets/icons/results-tab-icon.svg';
import { ConsoleTabs } from '../../../../constants/console.tabs.enum';
import { FinalizePendingSelector, TestsPendingSelector, getSelectedTab } from '../../../../store/selectors/editor';
import { actions } from '../../../../store/slices/editor';

const tabsIconSx = {
    color: 'primary.main'
}

enum tabLabels {
    TEST_TAB = 'Проверочные тесты',
    RESULT_TAB = 'Результат выполнения'
};

export const ConsoleMenu = ({
    visible,
    toggleVisible,
    horizontal,
    toggleHorizontal,
    isExecCodeButtonDisabled,
    onExecCodeButtonClick,
    consoleEditorValue,
    onTesting
}: TconsoleMenuProps) => {
    const dispatch = useAppDispatch();
    const taskNumber = useAppSelector(CurrentTaskNumberSelector);
    const tasks = useAppSelector(TasksSelector);
    const userCode = useAppSelector(CandidateCodeSelector);
    const convergenceId = useAppSelector(CurrentConvergenceIdSelector);
    const lang = useAppSelector(CurrentLangSelector);
    const selectedTab = useAppSelector(getSelectedTab);
    const testsPendingSelector = useAppSelector(TestsPendingSelector);
    const finalizePendingSelector = useAppSelector(FinalizePendingSelector);

    const handleFinishResponse = (response: Pick<CodeFinalizeResponse, 'meta'>) => {
        if (response.meta.requestStatus === RequestStatuses.FULFILLED) {
            dispatch(switchDialog(Dialogs.FINALIZE_WITH_ANSWERS));
            dispatch(isOpened());
        };
    };

    const handlePartialFinishResponse = (response: Pick<CodeFinalizeResponse, 'meta'>) => {
        if (response.meta.requestStatus === RequestStatuses.FULFILLED) {
            if (tasks && tasks?.tasks.length > 1 && tasks?.tasks.length - 1 !== taskNumber) {
                dispatch(setTaskNumber(taskNumber + 1));
            } else {
                onFinish();
            }
        };
    };

    const onSubmitButtonClick = async () => {
        if (userCode && convergenceId && lang) {
            const requestData: CodePartialFinalizeRequest = {
                modelId: convergenceId,
                lang: lang,
                userCode: userCode,
                taskId: (taskNumber + 1).toString(),
            };

            const response = await dispatch(consolePartialFinalize(requestData));

            if (response) {
                const responseData = {
                    meta: response.meta
                };

                handlePartialFinishResponse(responseData)
            };
        };
    };

    const onFinish = async () => {
        if (userCode) {
            const requestData = {
                userCode: userCode
            };
            const response = await dispatch(consoleFinalize(requestData));

            if (response) {
                const responseData = {
                    meta: response.meta
                };

                handleFinishResponse(responseData);
            };
        }
    };

    const textToArray = (text: string): string[] => {
        return text.replace(/\r/g, '').split('\n');
    };    

    const onTestButtonClick = async () => {
        onTesting();
        const customArguments = textToArray(consoleEditorValue);
        if (userCode && convergenceId && lang) {
            const requestData: CodeExecTestsRequest = {
                modelId: convergenceId,
                lang: lang,
                userCode: userCode,
                taskId: (taskNumber + 1).toString(),
                args: customArguments && consoleEditorValue ? customArguments : null
            };
            dispatch(tests(requestData));
        };
    };

    const onTabChange = (_event: SyntheticEvent<Element, Event>, value: ConsoleTabs) => {
        dispatch(actions.setTab(value));
    };

    return (
        <Box className="console__menu menu">
            {tasks ? (
                <Tabs value={selectedTab} onChange={onTabChange} className='console__tabs'>
                    <Tab value={ConsoleTabs.TESTS} className='console__tab' icon={<CheckBoxOutlined sx={tabsIconSx} />} iconPosition="start" label={tabLabels.TEST_TAB} />
                    <Tab value={ConsoleTabs.TEST_RESULTS} className='console__tab' icon={<img src={ResultsTabIcon} alt='results tab icon' />} iconPosition="start" label={tabLabels.RESULT_TAB} />
                </Tabs>
            ) : (
                <Typography className="menu__title">Консоль</Typography>
            )}

            <IconButton className="menu__button" onClick={toggleVisible}>
                {visible ? <KeyboardArrowDownOutlined className='menu__button-icon' /> : <KeyboardArrowUpOutlined className='menu__button-icon' />}
            </IconButton>
            <IconButton className="menu__button" onClick={toggleHorizontal} disabled={!!tasks}>
                {horizontal ? <KeyboardArrowLeftOutlined className='menu__button-icon' /> : <KeyboardArrowRightOutlined className='menu__button-icon' />}
            </IconButton>
            {
                tasks ? (
                    <Stack direction='row' spacing={1} className='menu__interview-buttons'>
                        <LoadingButton loading={testsPendingSelector} className='text-transform-none menu__interview-button' variant="outlined" size='small' onClick={onTestButtonClick} disabled={finalizePendingSelector}>
                            Тестировать
                        </LoadingButton>
                        <LoadingButton loading={finalizePendingSelector} className='text-transform-none menu__interview-button' variant="contained" size='small' onClick={onSubmitButtonClick} disabled={testsPendingSelector}>
                            Отправить на проверку
                        </LoadingButton>
                    </Stack>
                ) : (
                    <Button className="menu__start-button" onClick={onExecCodeButtonClick} disabled={isExecCodeButtonDisabled}>
                        <PlayArrowOutlined /> Пуск
                    </Button>
                )
            }
        </Box>
    );
};
