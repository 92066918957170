import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { WelcomeFrameTexts } from '../../constants/welcome-frame-texts.enum';
import './style.css';

export type WelcomeFrameItemProps = {
    onClickHandler: () => void;
    icon: React.ReactNode;
    text: WelcomeFrameTexts;
};

export const WelcomeFrameItem = ({ onClickHandler, icon, text }: WelcomeFrameItemProps) => (
    <Card className='welcome-frame__item' elevation={0} sx={{ flexGrow: 1, width: '100%' }} onClick={onClickHandler}>
        <CardActionArea sx={{ padding: '24px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100%' }}>
            {icon}
            <CardContent sx={{ padding: '24px 0 0' }}>
                <Typography align="center" variant="body2" color="text.primary">
                    {text}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
);
