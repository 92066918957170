import React from 'react';
import './style.css';

type DragHandleProps = {
    container: HTMLDivElement | null;
    setIsVisible: (value: boolean) => void;
    isVisible: boolean;
};

export const DragHandle = ({ container, setIsVisible, isVisible }: DragHandleProps) => {
    const initialPos = React.useRef(0);
    const initialHeight = React.useRef(0);
    
    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        if (!container) {
            return;
        }
        initialHeight.current = container.offsetHeight;
        initialPos.current = e.clientY;
    };
    const onDrag = (e: React.DragEvent<HTMLDivElement>) => {
        if (!container || !e.clientY) {
            return;
        }

        //top bound is 30% is window height
        const clientYBound = Math.max(e.clientY, window.innerHeight * 0.3);
        const height = Math.max(initialHeight.current - clientYBound + initialPos.current, 0);
        container.style.height = `${height}px`;

        if (!height && isVisible) {
            setIsVisible(false);
        }

        if (height && !isVisible) {
            setIsVisible(true);
        }
    };

    return <div draggable className="console__drag-handle" onDragStart={onDragStart} onDrag={onDrag} />;
};
