import React, { useEffect, useMemo, useState } from 'react';

import { Loader } from '../loader';

import { ConsoleMenu } from './components/consoleMenu';
import './style.css';
import { DragHandle } from './components/drag-handle';
import { useAppSelector } from '../../store';
import { CurrentTaskNumberSelector, TasksSelector } from '../../store/tasks/tasks.selector';
import { TestsSelector, getSelectedTab } from '../../store/selectors/editor';
import { ConsoleTabs } from '../../constants/console.tabs.enum';
import { Box, Typography } from '@mui/material';
import { CheckCircleOutlined, InfoOutlined } from '@mui/icons-material';
import { ConsoleEditor } from '../console-editor';
import cn from 'classnames';

export type TConsoleProps = {
    text: string;
    loaded: 'load' | 'loaded';
    horizontal: boolean;
    toggleHorizontal: () => void;
    onExecCodeButtonClick: () => void;
    isExecCodeButtonDisabled: boolean;
};

const successSX = {
    color: 'primary.main',
    width: '16px'
};

const warningSX = {
    color: 'warning.main',
    width: '16px'
};

export const Console = ({
    text,
    loaded,
    horizontal,
    toggleHorizontal,
    isExecCodeButtonDisabled,
    onExecCodeButtonClick,
}: TConsoleProps) => {
    const [isVisible, setIsVisible] = useState(true);
    const [consoleDefaultHeight, setConsoleDefaultHeight] = useState('257px');
    const containerRef = React.useRef<HTMLDivElement>(null);
    const lastVisibleHeight = React.useRef<string>('');
    const tasks = useAppSelector(TasksSelector);
    const selectedTab = useAppSelector(getSelectedTab);
    const testsData = useAppSelector(TestsSelector);
    const [consoleEditorValue, setConsoleEditorValue] = useState('');
    const currentTaskNumber = useAppSelector(CurrentTaskNumberSelector);

    useEffect(() => {
        if(tasks && !consoleEditorValue.length) {
            tasks.tasks[currentTaskNumber].examples.forEach((task) => {
                setConsoleEditorValue(prev => {
                    if(prev) {
                        return prev+`\n${task.input}`;
                    } else {
                        return `${task.input}`
                    }
                });
            });
        }
    }, [tasks, setConsoleEditorValue, currentTaskNumber]);

    const onTesting = () => {
        if(!isVisible) {
            setIsVisible(true);
            setConsoleDefaultHeight('257px');
        };
    };

    useEffect(() => {
        if (tasks) {
            setIsVisible(false);
            setConsoleDefaultHeight('0px');
        } else {
            setIsVisible(true);
            setConsoleDefaultHeight('257px');
        }
    }, [tasks]);

    useEffect(() => {
        if (!containerRef.current) return;

        if (horizontal) {
            if (containerRef.current.style.height !== '0px') {
                lastVisibleHeight.current = containerRef.current.style.height;
            }
            containerRef.current.style.removeProperty('height');
        } else {
            containerRef.current.style.height = lastVisibleHeight.current || consoleDefaultHeight;
        }
    }, [horizontal]);

    const onToggleVisible = () => {
        setIsVisible((prev) => {
            if (!containerRef.current) return prev;

            containerRef.current.style.transition = 'height 0.3s ease-out';
            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.style.transition = '';
                }
            }, 300);

            if (!prev) {
                containerRef.current.style.height = lastVisibleHeight.current || '257px';
            } else {
                if (containerRef.current.style.height !== '0px') {
                    lastVisibleHeight.current = containerRef.current.style.height;
                }
                containerRef.current.style.height = '0px';
            }

            return !prev;
        });
    };

    const getTestResults = useMemo(() => {
        if (testsData && testsData.results) {
            const errorResult = testsData.results.find(result => result.execResult && result.execResult.stdErr);
            const isSomeTestFailure = testsData.results.some(result => !result.success);

            if (errorResult) {
                return <Typography className='console__results-text' sx={warningSX}>Ошибка выполнения</Typography>;
            } else {
                return (
                    <Box className='console__tests-wrapper'>
                        {isSomeTestFailure ? (
                            <Typography key='testFailure' className='console__results-text' sx={warningSX}>Не все тесты пройдены</Typography>
                        ) : (
                            <Typography key='testUnFailure' className='console__results-text' sx={successSX}>Все тесты пройдены успешно</Typography>
                        )}
                        <Box className='console__results-wrapper'>
                            {testsData.results.map((result, id) => (
                                result.success ? (
                                    <Box key={result.testId} className='console__tests-result'>
                                        <CheckCircleOutlined className='console__tests-result-icon' sx={successSX} />
                                        <Typography className='console__results-text'>Тест {id + 1} пройден</Typography>
                                    </Box>
                                ) : (
                                    <Box key={result.testId} className='console__tests-result'>
                                        <InfoOutlined className='console__tests-result-icon' sx={warningSX} />
                                        <Typography className='console__results-text'>Тест {id + 1} не пройден</Typography>
                                    </Box>
                                )
                            ))}
                        </Box>
                    </Box>
                );
            }
        }

        return (<Typography className='console__results-text'>Результатов нет. Запустите тестирование.</Typography>);
    }, [testsData]);

    const renderConsoleContent = () => {
        switch (selectedTab) {
            case ConsoleTabs.TEST_RESULTS:
                return getTestResults;
            case ConsoleTabs.TESTS:
                return <ConsoleEditor setConsoleEditorValue={setConsoleEditorValue} value={consoleEditorValue} />;
            default:
                return null;
        }
    };

    return (
        <section className="editor-page__console">
            {!horizontal && (
                <DragHandle container={containerRef.current} setIsVisible={setIsVisible} isVisible={isVisible} />
            )}
            <div className="console__content-wrapper" ref={containerRef} style={{ height: consoleDefaultHeight }}>
                {loaded === 'load' && <Loader fullScreen={false} />}
                <div className={cn('console__content', tasks ? 'console__content-with-tasks' : 'console__content-without-tasks')}>
                    {tasks ? renderConsoleContent() : text}
                </div>
            </div>
            <ConsoleMenu
                visible={isVisible}
                toggleVisible={onToggleVisible}
                horizontal={horizontal}
                toggleHorizontal={toggleHorizontal}
                isExecCodeButtonDisabled={isExecCodeButtonDisabled}
                onExecCodeButtonClick={onExecCodeButtonClick}
                consoleEditorValue={consoleEditorValue}
                onTesting={onTesting}
            />
        </section>
    );
};
