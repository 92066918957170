import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { loadWASM } from 'onigasm';

import { THEME } from './theme';
import { instance as i18n } from './localization';
import { store } from './store/';
import { App } from './app';

import './monaco';

import './global.css';
import { Loader } from './components';

(async () => {
    await loadWASM('./onigasm/onigasm.wasm');
    ReactDOM.render(
        <Suspense fallback={<Loader />}>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={THEME}>
                    <CssBaseline />
                    <Provider store={store}>
                        <SnackbarProvider>
                            <App />
                        </SnackbarProvider>
                    </Provider>
                </ThemeProvider>
            </I18nextProvider>
        </Suspense>,
        document.getElementById('root'),
    );
})();
