const BASIC_PREFIX = 'tasks';

export const TaskThunkPrefixes = {
  FETCH_TASKS: `${BASIC_PREFIX}/fetchTasks`,
  PREVIEW: `${BASIC_PREFIX}/preview`,
  START: `${BASIC_PREFIX}/start`,
  DELETE: `${BASIC_PREFIX}/delete`,
  TESTS_WITH_OWN_ARGUMENTS: `${BASIC_PREFIX}/testsWithOwnArguments`,
  PARTIAL_FINALIZE: `${BASIC_PREFIX}/partialFinalize`,
  CONSOLE_PARTIAL_FINALIZE: `${BASIC_PREFIX}/consolePartialFinalize`,
  FINALIZE: `${BASIC_PREFIX}/finalize`,
  CONSOLE_FINALIZE: `${BASIC_PREFIX}/consoleFinalize`
} as const;