import { TChangeInterviewSearchParam, TGetInteviewSearchParam } from './index.types';

export const getInterviewPathName: TGetInteviewSearchParam = (name) => {
    const pathSegments = window.location.pathname.split('/');
    return pathSegments.includes(name) ? name : '';
};

export const changeInterviewSearchParam: TChangeInterviewSearchParam = (type, name) => {
    if (type === 'set' && !name) {
        throw new Error('Для типа "set" не передан аргумент name');
    };

    const { protocol, host, pathname, search } = window.location;

    let newPathname = pathname;

    if (type === 'set' && name) {
        newPathname = `/${name}`;
    }

    if (type === 'delete') {
        newPathname = '/';
    }

    const newSearchParams = new URLSearchParams(search);
    const newSearchString = newSearchParams.toString().length > 0 ? `?${newSearchParams.toString()}` : '';

    const newUrl = `${protocol}//${host}${newPathname}${newSearchString}`;

    window.history.pushState({ path: newUrl }, '', newUrl);
};
