import React from 'react';

import './style.css';
import { Box, Typography } from '@mui/material';

export const TaskDescriptionHeader = () => (
    <Box className='task-description__header'>
        <Typography className='task-description__header-title' variant='subtitle1' component='h2'>
            ЗАДАЧА
        </Typography>
    </Box>
);