import { TStoreState } from '..';

export const getLoaded = (state: TStoreState) => state.editor.loaded;
export const getErrorText = (state: TStoreState) => state.editor.errorText;
export const getConsoleText = (state: TStoreState) => state.editor.console.text;
export const getConsoleLoaded = (state: TStoreState) => state.editor.console.loaded;
export const getSelectedTab = (state: TStoreState) => state.editor.console.selectedTab;
export const TestsSelector = (state: TStoreState) => state.editor.testsWithOwnArguments;
export const TestsPendingSelector = (state: TStoreState) => state.editor.testsPending;
export const ConsoleFinalizeSelector = (state: TStoreState) => state.editor.finalize;
export const FinalizePendingSelector = (state: TStoreState) => state.editor.finalizePending;