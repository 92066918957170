import { createSlice } from '@reduxjs/toolkit';
import { fetchTasks, previewTasks, startTasks, partialFinalize, finalize } from './tasks.actions';
import { TasksInfo, TasksPreviewInfo } from '../../shared/types/tasks-info.type';
import { CodeFinalizeResponse, StartTestsResponse } from '../../shared/types/tests.type';

type TasksError = {
    status?: number;
    error?: string;
    message?: string;
};

type TasksState = {
    pending: boolean;
    errors: TasksError | null;
    tasksInfo: TasksInfo | null;
    tasksPreview: TasksPreviewInfo | null;
    taskNumber: number;
    started: StartTestsResponse;
    finalize: CodeFinalizeResponse | null;
};

const initialState: TasksState = {
    pending: false,
    errors: null,
    tasksInfo: null,
    tasksPreview: null,
    taskNumber: 0,
    started: {
        started: false
    },
    finalize: null,
};

export const TasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setTaskNumber: (state, action) => {
            state.taskNumber = action.payload;
        },
        clearErrors: (state) => {
            state.errors = null;
        },
        clearTasks: (state) => {
            state.tasksInfo = null,
            state.tasksPreview = null,
            state.taskNumber = 0,
            state.started = {
                started: false
            },
            state.finalize = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTasks.pending, (state) => {
                state.pending = true;
            })
            .addCase(fetchTasks.fulfilled, (state, action) => {
                state.pending = false;
                state.tasksInfo = action.payload;
                state.errors = null;
            })
            .addCase(fetchTasks.rejected, (state, action) => {
                state.pending = false;
                state.errors = action.payload as { message: string };
            })
            .addCase(previewTasks.pending, (state) => {
                state.pending = true;
            })
            .addCase(previewTasks.fulfilled, (state, action) => {
                state.pending = false;
                state.tasksPreview = action.payload;
                state.errors = null;
            })
            .addCase(previewTasks.rejected, (state, action) => {
                state.pending = false;
                state.errors = action.payload as { message: string };
            })
            .addCase(startTasks.pending, (state) => {
                state.pending = true;
            })
            .addCase(startTasks.fulfilled, (state, action) => {
                state.pending = false;
                state.started = action.payload;
                state.errors = null;
            })
            .addCase(startTasks.rejected, (state, action) => {
                state.pending = false;
                state.errors = action.payload as { message: string };
            })
            .addCase(partialFinalize.pending, (state) => {
                state.pending = true;
            })
            .addCase(partialFinalize.fulfilled, (state) => {
                state.pending = false;
                state.errors = null;
            })
            .addCase(partialFinalize.rejected, (state, action) => {
                state.pending = false;
                state.errors = action.payload as { message: string };
            })
            .addCase(finalize.fulfilled, (state, action) => {
                state.pending = false;
                state.finalize = action.payload;
                state.errors = null;
            })
            .addCase(finalize.pending, (state) => {
                state.pending = true;
            })
            .addCase(finalize.rejected, (state, action) => {
                state.pending = false;
                state.errors = action.payload as { message: string };
            })
    },
});

export const { setTaskNumber, clearErrors, clearTasks } = TasksSlice.actions;

export default TasksSlice.reducer;
