import ru from './locales/ru.json';

const LOCALES = {
    ru: ru,
    en: {},
};

function format(message, args) {
    let result;

    if (args.length === 0) {
        result = message;
    } else {
        result = message.replace(/\{(\d+)\}/g, function (match, rest) {
            const index = rest[0];

            return typeof args[index] !== 'undefined' ? args[index] : match;
        });
    }

    return result;
}

export function localize(path, data, defaultMessage) {
    const lng = window.localStorage.getItem('lng');
    const locales = LOCALES[lng];

    let args = [];
    const key = typeof data === 'object' ? data.key : data;
    let message = (locales[path] || {})[key];

    if (!message) {
        message = defaultMessage;
    }

    for (let i = 3; i < arguments.length; i++) {
        args[i - 3] = arguments[i];
    }

    return format(message, args);
}
