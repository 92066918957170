import * as ConvergenceColorAssigner from '@convergence/color-assigner';
import {
    LocalModelReference,
    ModelReference,
    RealTimeString,
    RemoteReferenceCreatedEvent,
    StringInsertEvent,
    StringRemoveEvent,
} from '@convergence/convergence';
import * as MonacoCollabExt from '@convergencelabs/monaco-collab-ext';
import { editor } from 'monaco-editor';
import type { IDisposable } from 'monaco-editor';

export class MonacoAdapter {
    _monacoEditor: editor.IStandaloneCodeEditor;

    _model: RealTimeString;

    _colorAssigner: ConvergenceColorAssigner.ColorAssigner;

    _contentManager?: MonacoCollabExt.EditorContentManager;

    _remoteCursorManager?: MonacoCollabExt.RemoteCursorManager;

    _cursorReference?: LocalModelReference<number, ModelReference>;

    _remoteSelectionManager?: MonacoCollabExt.RemoteSelectionManager;

    _selectionReference?: LocalModelReference<{ start: number; end: number }, ModelReference>;

    _disposeCursorHandler: IDisposable | null = null;

    _disposeSelectionHandler: IDisposable | null = null;

    constructor(
        monacoEditor: editor.IStandaloneCodeEditor,
        realtimeString: RealTimeString,
        colorAssigner: ConvergenceColorAssigner.ColorAssigner,
    ) {
        this._monacoEditor = monacoEditor;
        this._model = realtimeString;
        this._colorAssigner = colorAssigner;
    }

    bind() {
        this._initSharedData();
        this._initSharedCursors();
        this._initSharedSelection();
    }

    dispose() {
        this._contentManager?.dispose();
        this._disposeCursorHandler?.dispose();
        this._disposeSelectionHandler?.dispose();
    }

    _initSharedData() {
        this._contentManager = new MonacoCollabExt.EditorContentManager({
            editor: this._monacoEditor,
            onInsert: (index, text) => {
                this._model.insert(index, text);
            },
            onReplace: (index, length, text) => {
                this._model.model().startBatch();
                this._model.remove(index, length);
                this._model.insert(index, text);
                this._model.model().completeBatch();
            },
            onDelete: (index, length) => {
                this._model.remove(index, length);
            },
            remoteSourceId: 'convergence',
        });

        this._model.events().subscribe((e) => {
            switch (e.name) {
                case 'insert': {
                    const event = e as StringInsertEvent;
                    this._contentManager?.insert(event.index, event.value);
                    break;
                }
                case 'remove': {
                    const event = e as StringRemoveEvent;
                    this._contentManager?.delete(event.index, event.value.length);
                    break;
                }
                default:
            }
        });
    }

    _initSharedCursors() {
        this._remoteCursorManager = new MonacoCollabExt.RemoteCursorManager({
            editor: this._monacoEditor,
            tooltips: true,
            tooltipDuration: 2,
        });

        this._cursorReference = this._model.indexReference('cursor');

        const references = this._model.references({ key: 'cursor' });
        references.forEach((reference) => {
            if (!reference.isLocal()) {
                this._addRemoteCursor(reference);
            }
        });

        this._setLocalCursor();
        this._cursorReference.share();

        this._disposeCursorHandler = this._monacoEditor.onDidChangeCursorPosition(() => {
            this._setLocalCursor();
        });

        this._model.on('reference', (e) => {
            const event = e as RemoteReferenceCreatedEvent;
            if (event.reference.key() === 'cursor') {
                this._addRemoteCursor(event.reference);
            }
        });
    }

    _setLocalCursor() {
        const position = this._monacoEditor.getPosition();

        if (position === null) {
            return;
        }

        const model = this._monacoEditor.getModel();

        if (!model) {
            return;
        }

        const offset = model.getOffsetAt(position);

        this._cursorReference?.set(offset);
    }

    _addRemoteCursor(reference: ModelReference) {
        const color = this._colorAssigner.getColorAsHex(reference.sessionId());
        const remoteCursor = this._remoteCursorManager?.addCursor(
            reference.sessionId(),
            color,
            reference.user().displayName,
        );

        if (!remoteCursor) {
            return;
        }

        reference.on('cleared', () => remoteCursor.hide());
        reference.on('disposed', () => remoteCursor.dispose());
        reference.on('set', () => {
            const cursorIndex = reference.value();
            remoteCursor.setOffset(cursorIndex);
        });
    }

    _initSharedSelection() {
        this._remoteSelectionManager = new MonacoCollabExt.RemoteSelectionManager({ editor: this._monacoEditor });

        this._selectionReference = this._model.rangeReference('selection');
        this._setLocalSelection();
        this._selectionReference.share();

        this._disposeSelectionHandler = this._monacoEditor.onDidChangeCursorSelection(() => {
            this._setLocalSelection();
        });

        const references = this._model.references({ key: 'selection' });
        references.forEach((reference) => {
            if (!reference.isLocal()) {
                this._addRemoteSelection(reference);
            }
        });

        this._model.on('reference', (e) => {
            const event = e as RemoteReferenceCreatedEvent;
            if (event.reference.key() === 'selection') {
                this._addRemoteSelection(event.reference);
            }
        });
    }

    _setLocalSelection() {
        const selection = this._monacoEditor.getSelection();
        const model = this._monacoEditor.getModel();

        if (!model) {
            return;
        }

        if (selection && !selection.isEmpty()) {
            const start = model.getOffsetAt(selection.getStartPosition());
            const end = model.getOffsetAt(selection.getEndPosition());
            this._selectionReference?.set({ start, end });
        } else if (this._selectionReference?.isSet()) {
            this._selectionReference.clear();
        }
    }

    _addRemoteSelection(reference: ModelReference) {
        const color = this._colorAssigner.getColorAsHex(reference.sessionId());

        if (!this._remoteSelectionManager) {
            return;
        }

        const remoteSelection = this._remoteSelectionManager.addSelection(reference.sessionId(), color);

        if (reference.isSet()) {
            const selection = reference.value();
            remoteSelection.setOffsets(selection.start, selection.end);
        }

        reference.on('cleared', () => remoteSelection.hide());
        reference.on('disposed', () => remoteSelection.dispose());
        reference.on('set', () => {
            const selection = reference.value();
            remoteSelection.setOffsets(selection.start, selection.end);
        });
    }
}
