import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { actions } from './store/slices/app';
import { EditorPage } from './pages/editor';
import { getErrorText, getLoaded } from './store/selectors/app';
import { Loader } from './components';

export const App = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const loaded = useSelector(getLoaded);
    const error = useSelector(getErrorText);

    useEffect(() => {
        i18n.on('failedLoading', () => {
            dispatch(actions.setCriticalLoaded('Ошибка загрузки файлов локализации'));
        });

        return () => {
            i18n.off('failedLoading');
        };
    }, [dispatch, i18n]);

    if (loaded === 'initializing' || loaded === 'load') {
        return <Loader />;
    }

    if ((loaded === 'error' || loaded === 'critical') && error) {
        return <h2>Error</h2>;
    }

    return <EditorPage />;
};
