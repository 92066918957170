import { createSlice } from '@reduxjs/toolkit';

import { InitialStateDefault } from './defaults';
import { TSliceDefaultStateWrapper } from './defaults.types';
import { TSetCriticalLoadedAction, TState } from './app.types';

const initialState: TSliceDefaultStateWrapper<TState> = {
    ...InitialStateDefault,
    loaded: 'loaded',
};

const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCriticalLoaded: (state, action: TSetCriticalLoadedAction) => {
            state.errorText = action.payload;
            state.loaded = 'critical';
        },
    },
});

export const { reducer, actions } = app;
