import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Convergence, RealTimeModel, RealTimeString } from '@convergence/convergence';
import { editor } from 'monaco-editor';
import { ColorAssigner } from '@convergence/color-assigner';

import { instance } from '../../store/instance';
import { MonacoAdapter } from '../../convergence/adapters/monaco';
import { actions } from '../../store/slices/editor';
import { createError } from '../../store/operations/utils';
import { TCollaborator } from '../../pages/editor/index.types';

import { EditorRoot, Header, Statusbar } from './components';
import { TEditorInstance, TLanguageState } from './index.types';

import './style.css';
import { useAppSelector } from '../../store';
import { TasksSelector } from '../../store/tasks/tasks.selector';
import cn from 'classnames';
import { CandidateCodeSelector } from '../../store/dialog/dialog.selector';

export type TEditorProps = {
    languageState: TLanguageState;
    convergenceId?: string;
    username?: string;
    lsp: boolean;
    setLsp: (value: boolean) => void;
    setCollaborators: (value: TCollaborator[]) => void;
    language: string;
    setLanguage: (language: string) => void;
};

type UseCollabSessionProps = {
    convergenceId?: string;
    username?: string;
    editorInstance: TEditorInstance;
    setCollaborators: (value: TCollaborator[]) => void;
};

interface IUseCollaboratorsProps {
    onChange: (v: TCollaborator[]) => void;
    colorAssigner: ColorAssigner;
}

const useCollaborators = ({ onChange, colorAssigner }: IUseCollaboratorsProps) => {
    const [model, setModel] = useState<RealTimeModel>();

    useEffect(() => {
        if (!model) {
            return;
        }

        const updateCollabs = () => {
            const users = model?.collaborators().map(({ user, sessionId }) => ({
                displayName: user.displayName ?? '',
                color: colorAssigner.getColorAsHex(sessionId),
            }));

            onChange(users ?? []);
        };

        updateCollabs();

        model.addListener('collaborator_opened', updateCollabs);
        model.addListener('collaborator_closed', updateCollabs);

        return () => {
            model.removeListener('collaborator_opened', updateCollabs);
            model.removeListener('collaborator_closed', updateCollabs);
        };
    }, [model, onChange, colorAssigner]);

    return { setModel };
};

const useCollabSession = ({ editorInstance, convergenceId, username, setCollaborators }: UseCollabSessionProps) => {
    const colorAssigner = useRef(new ColorAssigner());
    const model = useRef<RealTimeModel>();
    const adapter = useRef<MonacoAdapter>();
    const candidateCode = useAppSelector(CandidateCodeSelector);
    const dispatch = useDispatch();
    const { setModel } = useCollaborators({ onChange: setCollaborators, colorAssigner: colorAssigner.current });

    const init = useCallback(
        async (args: { convergenceId: string; username: string; editorInstance: editor.IStandaloneCodeEditor }) => {
            dispatch(actions.connectCollabSessionPending());
            
            try {
                try {
                    const {
                        data: { text },
                    } = await instance.post(`${process.env.REACT_APP_API_URL}/code/model`, { id: args.convergenceId });

                    if (text) {
                        args.editorInstance.setValue(text);
                    }
                } catch (error) {}

                try {
                    const domain = await Convergence.connectAnonymously(
                        process.env.REACT_APP_CONVERGENCE_URL || '',
                        args.username,
                    );

                    const models = await domain.models();

                    models.history(args.convergenceId);

                    model.current = await models.openAutoCreate({
                        collection: 'monaco-collab',
                        id: args.convergenceId,
                        data: {
                            text: args.editorInstance.getValue(),
                        },
                    });

                    setModel(model.current);

                    args.editorInstance.setValue(model.current.elementAt('text').value());

                    adapter.current = new MonacoAdapter(
                        args.editorInstance,
                        model.current.elementAt('text') as RealTimeString,
                        colorAssigner.current,
                    );

                    adapter.current.bind();

                    dispatch(actions.connectCollabSessionFulfilled());
                } catch (error) {
                    throw new Error(`Ошбика при инициализации модуля convergence "monaco". ${error}`);
                }
            } catch (error) {
                dispatch(actions.connectCollabSessionRejected(createError(error)));
            }
        },
        [dispatch, setModel],
    );
    useEffect(() => {
        
        if (editorInstance && convergenceId && username) {
            init({ editorInstance, convergenceId, username: username });
        } else if (editorInstance && convergenceId && candidateCode) {
            init({ editorInstance, convergenceId, username: candidateCode });
            localStorage.setItem('convergenceId', convergenceId);
        };

        return () => {
            model.current?.close();
            adapter.current?.dispose();
        };
    }, [editorInstance, convergenceId, username, candidateCode, init]);
};

export const Editor = ({
    languageState,
    convergenceId,
    username,
    lsp,
    setLsp,
    setCollaborators,
    language,
    setLanguage,
}: TEditorProps) => {
    const [editorInstance, setEditorInstance] = useState<TEditorInstance>(null);
    const tasks = useAppSelector(TasksSelector);

    useCollabSession({ convergenceId, username, editorInstance, setCollaborators });

    return (
        <section className={cn("editor-page__editor editor", tasks ? 'editor--with-tasks' : 'editor--without-tasks')}>
            <Header
                lsp={lsp}
                setLsp={setLsp}
                editorInstance={editorInstance}
                language={language}
                setLanguage={setLanguage}
            />
            <EditorRoot
                languageState={languageState}
                lsp={lsp}
                convergenceId={convergenceId}
                setEditorInstance={setEditorInstance}
            />
            <Statusbar editorInstance={editorInstance} />
        </section>
    );
};
