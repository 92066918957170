/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuid } from 'uuid';

import { getLink } from './utils';

export class Sync {
    _uuid: string = uuid();

    _connection: WebSocket | null = null;

    _subscribers: {
        [key: string]: (data: any) => void;
    } = {};

    _call(name: string, message: string) {
        if (this._subscribers[name]) {
            this._subscribers[name](message);
        }
    }

    open(roomId: string) {
        this._connection = new WebSocket(getLink(roomId, this._uuid));
        this._connection.onmessage = ({ data }) => {
            const parsedMessage = JSON.parse(data);

            if (parsedMessage.name) {
                this._call(parsedMessage.name, parsedMessage.data);
            }
        };
    }

    close() {
        if (this._connection) {
            this._connection.close();
            this._connection = null;
            this._subscribers = {};
        }
    }

    subscribe(name: string, callback: (data: any) => void) {
        if (!this._subscribers[name]) {
            this._subscribers[name] = callback;
        } else {
            throw new Error(`Дублирование слушателя: ${name}`);
        }
    }

    unsubscribe(name: string) {
        if (this._subscribers[name]) {
            delete this._subscribers[name];
        }
    }

    send(name: string, data: any) {
        if (this._connection && this._connection.readyState === 1) {
            const message = {
                name,
                data,
            };
            this._connection.send(JSON.stringify(message));
        }
    }
}

export const SyncInstance = new Sync();
