import { TChangeSessionSearchParam, TGetSessionSearchParam } from './index.types';

export const getSessionSearchParam: TGetSessionSearchParam = (name) => new URLSearchParams(window.location.search).get(name) || '';

export const changeSessionSearchParam: TChangeSessionSearchParam = (type, name, value) => {
    if (type === 'set' && !value) {
        throw new Error('Для типа "set" не передан аргумент value');
    }

    const { protocol, host, pathname, search } = window.location;

    const newSearchParams = new URLSearchParams(search);

    if (type === 'set' && value) {
        newSearchParams.set(name, value);
    }

    if (type === 'delete') {
        newSearchParams.delete(name);
    }

    const newSearchString = newSearchParams.toString().length > 0 ? `?${newSearchParams.toString()}` : '';

    const newUrl = `${protocol}//${host}${pathname.slice(0, -1)}${newSearchString}`;

    window.history.pushState({ path: newUrl }, '', newUrl);
};
