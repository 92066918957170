import React from 'react';
import { Box, DialogContent } from '@mui/material';
import { WelcomeFrameItem } from '../welcome-frame-item';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { WelcomeFrameTexts } from '../../constants/welcome-frame-texts.enum';

import { useAppDispatch } from '../../store';
import { switchDialog } from '../../store/dialog/dialog.slice';
import { Dialogs } from '../../constants/dialogs.enum';

const iconStyles = { maxWidth: '32px', maxHeight: '32px', width: '100%', height: '100%', fill: '#4CAF50' };

export const WelcomeFrame = () => {
    const dispatch = useAppDispatch();

    const onCodeClickHandler = () => {
        dispatch(switchDialog(Dialogs.CREATE_SESSION_DIALOG));
    };

    const onInterviewClickHandler = () => {
        dispatch(switchDialog(Dialogs.INTERVIEW_DIALOG));
    };

    const welcomeFrameItemsData = [
        {
            icon: <CodeOutlinedIcon sx={iconStyles} />,
            text: WelcomeFrameTexts.SESSION_CREATE,
            onClickHandler: onCodeClickHandler,
        },
        {
            icon: <AssessmentOutlinedIcon sx={iconStyles} />,
            text: WelcomeFrameTexts.SESSION_INTERVIEW,
            onClickHandler: onInterviewClickHandler,
        },
    ];

    return (
        <Box component="div">
            <DialogContent sx={{ display: 'flex', gap: '16px', backgroundColor: '#F6F3F5' }}>
                {welcomeFrameItemsData.map((item, id) => (
                    <WelcomeFrameItem
                        key={id}
                        onClickHandler={item.onClickHandler}
                        icon={item.icon}
                        text={item.text}
                    />
                ))}
            </DialogContent>
        </Box>
    );
};
