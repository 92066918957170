import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { DEFAULT_POSITION } from './constants';
import { TOnDidChangeCursorSelectionn, TPosition, TStatusbarProps } from './index.types';

import './style.css';

export const Statusbar = ({ editorInstance }: TStatusbarProps) => {
    const [{ line, selected }, setPosition] = useState<TPosition>(DEFAULT_POSITION);

    const selectedLength = selected.reduce((result, current) => result + current, 0);
    const mainText = selected.length > 1 ? `Выделений: ${selected.length}` : `Строка ${line}`;
    const selectedText = selectedLength > 0 ? ` (выделено символов: ${selectedLength})` : '';

    const onDidChangeCursorSelection: TOnDidChangeCursorSelectionn = useCallback(
        (selection, secondarySelections) => {
            const model = editorInstance && editorInstance.getModel();

            if (model && selection) {
                const selections = [selection, ...(secondarySelections || [])];

                setPosition({
                    column: selection.positionColumn,
                    line: selection.positionLineNumber,
                    selected: selections.map((range) => model.getValueLengthInRange(range)),
                });
            }
        },
        [editorInstance],
    );

    useEffect(() => {
        if (editorInstance) {
            onDidChangeCursorSelection(editorInstance.getSelection());
            editorInstance.onDidChangeCursorSelection((event) => {
                onDidChangeCursorSelection(event.selection, event.secondarySelections);
            });
        }
    }, [editorInstance, onDidChangeCursorSelection]);

    return (
        <footer className="editor__statusbar statusbar">
            <Typography className="statusbar__text" variant="caption">{`${mainText}${selectedText}`}</Typography>
        </footer>
    );
};
