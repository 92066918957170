import { CloseAction, ErrorAction, MonacoLanguageClient } from 'monaco-languageclient';
import normalizeUrl from 'normalize-url';
import { toSocket, WebSocketMessageReader, WebSocketMessageWriter } from 'vscode-ws-jsonrpc';

import { DISABLED } from './constants';
import { TCreateLanguageClient, TCreateLSPConnection, TCreateUrl, TLanguageClient } from './index.types';

const createLanguageClient: TCreateLanguageClient = (transports, selector) => {
    return new MonacoLanguageClient({
        name: 'Simple Language Client',
        clientOptions: {
            documentSelector: selector,
            errorHandler: {
                error: () => ({ action: ErrorAction.Continue }),
                closed: () => ({ action: CloseAction.DoNotRestart }),
            },
        },
        connectionProvider: {
            get: () => {
                return Promise.resolve(transports);
            },
        },
    });
};

const createUrl: TCreateUrl = (language, id = 'unknown_id') => {
    const rootUrl = process.env.REACT_APP_LSP_URL || '';
    return normalizeUrl(`${rootUrl}/${language}/${id}`);
};

export const createLSPConnection: TCreateLSPConnection = ({ language, selector, id }) => {
    if (DISABLED.includes(language)) {
        return () => {};
    }

    const url = createUrl(language, id);
    const ws = new WebSocket(url);

    let languageClient: TLanguageClient | null = null;

    const stopLanguageClient = () => {
        if (languageClient) {
            languageClient.stop().then(() => {
                if (ws && ws.readyState === ws.OPEN) {
                    ws.close();
                }
            });

            languageClient = null;
        }
    };

    ws.onopen = () => {
        const socket = toSocket(ws);

        const reader = new WebSocketMessageReader(socket);
        const writer = new WebSocketMessageWriter(socket);

        languageClient = createLanguageClient(
            {
                reader,
                writer,
            },
            selector,
        );

        languageClient.start();

        reader.onClose(stopLanguageClient);
    };

    return () => {
        stopLanguageClient();
    };
};
