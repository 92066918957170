import { createSlice } from '@reduxjs/toolkit';
import { Dialogs } from '../../constants/dialogs.enum';

type DialogState = {
    dialog: Dialogs;
    isOpened: boolean;
    candidateCode: string | null;
};

const initialState: DialogState = {
    dialog: Dialogs.WELCOME_DIALOG,
    isOpened: true,
    candidateCode: null
};

export const DialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        switchDialog: (state, action) => {
            state.dialog = action.payload;
        },
        isOpened: (state) => {
            const previousState = state.isOpened;
            state.isOpened = !previousState;
        },
        setCode: (state, action) => {
            state.candidateCode = action.payload;
        },
    },
});

export const { switchDialog, isOpened, setCode } = DialogSlice.actions;

export default DialogSlice.reducer;
