import { createSlice } from '@reduxjs/toolkit';

import { execCode } from '../operations/editor';
import { SyncInstance } from '../../sync';

import { fulfilledDefault, InitialStateDefault, pendingDefault, rejectedDefault } from './defaults';
import { TSliceDefaultStateWrapper } from './defaults.types';
import { TSetConsoleLoadedAction, TSetConsoleTextAction, TState } from './editor.types';
import { ConsoleTabs } from '../../constants/console.tabs.enum';
import { consoleFinalize, consolePartialFinalize, tests } from '../tasks/tasks.actions';

const initialState: TSliceDefaultStateWrapper<TState> = {
    session: {
        status: 'inactive',
    },
    console: {
        text: '',
        loaded: 'loaded',
        selectedTab: ConsoleTabs.TEST_RESULTS
    },
    testsWithOwnArguments: null,
    finalize: null,
    testsPending: false,
    finalizePending: false,
    ...InitialStateDefault,
};

const editor = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        connectCollabSessionPending: pendingDefault,
        connectCollabSessionFulfilled: (state) => {
            fulfilledDefault(state);
            state.session.status = 'active';
        },
        connectCollabSessionRejected: (state, action) => {
            rejectedDefault(state, action);
            state.session.status = initialState.session.status;
        },
        setConsoleText: (state, action: TSetConsoleTextAction) => {
            state.console.text = action.payload;
        },
        setConsoleLoaded: (state, action: TSetConsoleLoadedAction) => {
            state.console.loaded = action.payload;
        },
        setConvergenceId: (state, action) => {
            state.convergenceId = action.payload;
        },
        setLang: (state, action) => {
            state.lang = action.payload;
        },
        setTab: (state, action) => {
            state.console.selectedTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(execCode.pending, (state) => {
                state.console.loaded = 'load';
                SyncInstance.send('consoleLoaded', state.console.loaded);
            })
            .addCase(execCode.fulfilled, (state, action) => {
                state.console.loaded = 'loaded';
                state.console.text = action.payload.text;
                SyncInstance.send('console', state.console.text);
                SyncInstance.send('consoleLoaded', state.console.loaded);
            })
            .addCase(execCode.rejected, (state, action) => {
                rejectedDefault(state, action);
                state.console.loaded = 'loaded';
                state.console.text = initialState.console.text;
                SyncInstance.send('console', state.console.text);
                SyncInstance.send('consoleLoaded', state.console.loaded);
            })
            .addCase(tests.pending, (state) => {
                state.testsPending = true;
            })
            .addCase(tests.fulfilled, (state, action) => {
                state.testsPending = false;
                state.testsWithOwnArguments = action.payload;
            })
            .addCase(tests.rejected, (state, action) => {
                rejectedDefault(state, action);
                state.testsPending = false;
                state.testsWithOwnArguments = initialState.testsWithOwnArguments;
            })
            .addCase(consolePartialFinalize.pending, (state) => {
                state.finalizePending = true;
            })
            .addCase(consolePartialFinalize.fulfilled, (state) => {
                state.finalizePending = false;
            })
            .addCase(consolePartialFinalize.rejected, (state, action) => {
                rejectedDefault(state, action);
                state.finalizePending = false;
            })
            .addCase(consoleFinalize.pending, (state) => {
                state.finalizePending = true;
            })
            .addCase(consoleFinalize.fulfilled, (state, action) => {
                state.finalizePending = false;
                state.finalize = action.payload;
            })
            .addCase(consoleFinalize.rejected, (state, action) => {
                rejectedDefault(state, action);
                state.finalizePending = false;
            })
    },
});

export const { reducer, actions } = editor;
