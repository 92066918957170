// TODO fix MonacoEditor options

import React from 'react';
import MonacoEditor from '@monaco-editor/react';
import { MonacoServices } from 'monaco-languageclient';
import { Registry } from 'monaco-textmate';
import { wireTmGrammars } from 'monaco-editor-textmate';

import theme from '../../assets/themes/light.json';

import { EditorProps, TBeforeMount, TOnChange, TOnMount, TTheme } from './index.types';

import './style.css';

export const ConsoleEditor = ({ value, setConsoleEditorValue }: EditorProps) => {
    const loadVSCodeTheme = async (monaco: any, editor: any) => {
        const registry = new Registry({
            getGrammarDefinition: async (scopeName) => {
                if (scopeName == 'source.java') {
                    return {
                        format: 'json',
                        content: await (await fetch(`./tmLanguages/java.tmLanguage.json`)).text(),
                    };
                }
                if (scopeName == 'source.python') {
                    return {
                        format: 'json',
                        content: await (await fetch(`./tmLanguages/magicpython.tmLanguage.json`)).text(),
                    };
                }
                return {
                    format: 'json',
                    content: '',
                };
            },
        });

        const grammars = new Map();
        grammars.set('java', 'source.java');
        grammars.set('python', 'source.python');

        monaco.languages.register({ id: 'java' });
        monaco.languages.register({ id: 'python' });

        await wireTmGrammars(monaco, registry, grammars, editor);
    };

    const beforeMount: TBeforeMount = (monaco) => {
        monaco.editor.defineTheme('custom', theme as TTheme);
    };

    const onMount: TOnMount = (editor, monaco) => {
        MonacoServices.install();
        loadVSCodeTheme(monaco, editor).then(() => monaco.editor.setTheme('custom'));
    };

    const onChange: TOnChange = (newValue) => {
        setConsoleEditorValue(newValue || '');
    };

    return (
        <MonacoEditor
            wrapperProps={{
                className: 'console-editor__root',
            }}
            // @ts-ignore
            options={{
                automaticLayout: true,
                codeLens: false,
                lightbulb: {
                    enabled: false,
                },
                scrollBeyondLastLine: false,
                minimap: {
                    enabled: false,
                },
                fontFamily: '"Fira Code", monospace',
                fontSize: 14,
                wordWrap: 'on',
            }}
            theme="custom"
            value={value}
            onChange={onChange}
            onMount={onMount}
            beforeMount={beforeMount}
        />
    );
};
