import { createTheme } from '@mui/material';

export const THEME = createTheme({
    palette: {
        mode: 'light',
        divider: '#0000001f',
        primary: {
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: '#33383e',
        },
        text: {
            primary: '#000000de',
        },
        action: {
            active: '#2d3843',
            hover: '#0000000a',
            selected: '#4CAF5014',
            focus: '#4CAF501F',
        },
        info: {
            main: '#0288D1'
        },
        warning: {
            main: '#ed6c02'
        },
        background: {
            default: '#ebebeb'
        },
        grey: {
            500: '#9e9e9e'
        },
    },
    typography: {
        fontFamily: '"Roboto", "Open sans", sans-serif;',
        htmlFontSize: 16,
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.25rem'
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#2D3843',
                    ':hover': {
                        'background-color': 'transparent',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                },
                select: {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: '24px',
                    fontSize: '12px',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: '2px 0',
                },
            },
        },
    },
});