import React, { useLayoutEffect, useRef } from 'react';

import { VerticalDragHandle } from '../vertical-drag-handle';

import './style.css';
import { TaskDescriptionHeader } from '../task-description-header';
import { TaskDescriptionContent } from '../task-description-content';
import { useAppSelector } from '../../store';
import { TasksSelector } from '../../store/tasks/tasks.selector';

const TASK_DESCRIPTION_DEFAULT_WIDTH = 'calc(50vw - 16px)';

export type TaskDescriptionProps = {
    horizontal: boolean;
};

export const TaskDescription = ({
    horizontal
}: TaskDescriptionProps) => {
    const taskDescriptionRef = useRef<HTMLDivElement>(null);
    const lastVisibleWidth = useRef<string>('');
    const tasks = useAppSelector(TasksSelector);

    useLayoutEffect(() => {
        if (!taskDescriptionRef.current) {
            return;
        };

        if (horizontal) {
            if (taskDescriptionRef.current.style.width !== '0%') lastVisibleWidth.current = taskDescriptionRef.current.style.width;
            taskDescriptionRef?.current?.style.removeProperty('width');
        } else {
            taskDescriptionRef.current.style.width = lastVisibleWidth.current || TASK_DESCRIPTION_DEFAULT_WIDTH;
        };
        
    }, [horizontal, lastVisibleWidth, taskDescriptionRef, tasks]);

    return (
        <section className='task-description'>
            {!horizontal && (
                <VerticalDragHandle container={taskDescriptionRef} />
            )}
            <div className='task-description__content-wrapper' ref={taskDescriptionRef} style={{ width: TASK_DESCRIPTION_DEFAULT_WIDTH }}>
                <TaskDescriptionHeader />
                <TaskDescriptionContent />
            </div>
        </section>
    );
};
