import { ReactComponent as java } from './languages/java.svg';
import { ReactComponent as python } from './languages/python.svg';
import { ReactComponent as resultsTabIcon } from './results-tab-icon.svg';

type TIconGroup = 'languages' | 'tabIcons';

export const Icons: {
    [key in TIconGroup]: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> };
} = {
    languages: {
        java,
        python,
    },
    tabIcons: {
        resultsTabIcon
    }
};
