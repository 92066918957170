import axios from 'axios';
import axiosRetry from 'axios-retry';

import { instance as i18n } from '../localization';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

axiosRetry(instance, {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 100;
    },
    retryCondition: (error) => {
        if (error.response) {
            return error.response.status === 502 || error.response.status === 504;
        }
        return false;
    },
});


instance.interceptors.request.use((request) => {
    if (request.headers) {
        request.headers['Accept-Language'] = i18n.language;
    }

    return request;
});

export { instance };
